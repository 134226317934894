import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "components/layout"
import Seo from "components/seo"
import ReactHtmlParser from "html-react-parser"
import moment from "moment"

const NewsDetailPage = ({ data }) => {
  return (
    <Layout>
      <div className="article-page">
        <div className="entry-contents">
          <div className="entry-header">
            <h1 className="entry-title">{data.strapiInformation.title}</h1>
            <p className="published-date">
              {moment(data.strapiInformation.publishedAt).format(`YYYY.MM.DD`)}
            </p>
          </div>

          <div className="article-contents">
            {ReactHtmlParser(
              data.strapiInformation.childStrapiInformationBodyTextnode?.body ??
                ""
            )}
          </div>
        </div>

        <div className="link-wrap tx-center">
          <Link to="/" className="fw-b back-top">
            Back To Index
          </Link>
        </div>
      </div>
    </Layout>
  )
}
export const Head = ({ data }) => <Seo title={data.strapiInformation.title} />
export default NewsDetailPage

export const query = graphql`
  query ($slug: String) {
    strapiInformation(slug: { eq: $slug }) {
      title
      publishedAt
      slug
      childStrapiInformationBodyTextnode {
        body
      }
    }
  }
`
